import React, { RefObject } from 'react';
import cn from 'classnames';

import classes from './Input.module.scss';
import Props from './Input.props';

const Input = ({ name, value, label, error, disabled = false, ...otherProps }: Props, ref: any) => {
  return (
    <div className={classes.InputGroup}>
      <div className={cn(classes.Input, { [`${classes.valid}`]: !!value && value.length > 0 })}>
        <input
          id={name}
          ref={ref}
          name={name}
          defaultValue={value}
          {...otherProps}
          disabled={disabled}
        />
        {!!label && <label htmlFor={name}>{label}</label>}
      </div>
      {!!error && <p className={classes.Error}>{error}</p>}
    </div>
  );
};

export default React.forwardRef<RefObject<typeof Input>, Props>(Input);
