import React from 'react';
import * as Yup from 'yup';
import { Formik, Form, Field, FieldProps } from 'formik';
import { useTranslation } from 'react-i18next/';
import { faLongArrowRight } from '@fortawesome/pro-regular-svg-icons';

import { REGEXP } from '@constants/index';
import { useNewsletter } from '@hooks/useNewsletter';

import styles from './RegisterForm.module.scss';
import Props from './RegisterForm.props';
import Input from '@components/ui/Input/Input';
import Button from '@components/ui/Button/Button';
import CheckboxInput from '@components/ui/CheckboxInput/CheckboxInput';
import TextLink from '@components/ui/TextLink/TextLink';

const RegisterForm = ({
  initialValues,
  onSubmit,
  error,
  isLoading,
  message,
  termsLink,
  privacyLink,
}: Props) => {
  const { t } = useTranslation();
  const renderNewsletter = useNewsletter();

  const validationScheme = Yup.object().shape({
    email: Yup.string()
      .email(t('VALIDATION.E_MAIL_INVALID'))
      .required(t('VALIDATION.FIELD_REQUIRED')),
    password: Yup.string()
      .required(t('VALIDATION.FIELD_REQUIRED'))
      .matches(REGEXP.STRONG_PASSWORD, t('VALIDATION.WEAK_PASSWORD')),
    passwordVerify: Yup.string()
      .oneOf([Yup.ref('password'), null], t('VALIDATION.PASSWORDS_DONT_MATCH'))
      .required(t('VALIDATION.FIELD_REQUIRED')),
    acceptedTerms: Yup.boolean().oneOf([true], t('VALIDATION.TERMS_NOT_ACCEPTED')),
  });

  return (
    <div className={styles.RegisterForm}>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationScheme}
        validateOnBlur={false}
        validateOnChange>
        {() => {
          return (
            <Form>
              {message ? (
                <div data-testid="registerFormMessageContainer" className={styles.Message}>
                  {message}
                </div>
              ) : (
                <>
                  <div className="mb-sm">
                    <Field name="email">
                      {({ form, field }: FieldProps) => {
                        return (
                          <Input
                            label={t('DOMAIN.AUTHENTICATION.EMAIL')}
                            error={form.touched.email && (form.errors.email as string)}
                            data-testid="registerFormEmailInput"
                            type="email"
                            {...field}
                          />
                        );
                      }}
                    </Field>
                  </div>
                  <div className="mb-sm">
                    <Field name="password">
                      {({ form, field }: FieldProps) => {
                        return (
                          <Input
                            label={t('DOMAIN.AUTHENTICATION.PASSWORD')}
                            error={form.touched.password && (form.errors.password as string)}
                            type="password"
                            data-testid="registerFormPasswordInput"
                            {...field}
                          />
                        );
                      }}
                    </Field>
                  </div>
                  <div className="mb-sm">
                    <Field name="passwordVerify">
                      {({ form, field }: FieldProps) => {
                        return (
                          <Input
                            label={t('DOMAIN.AUTHENTICATION.REPEAT_PASSWORD')}
                            error={
                              form.touched.passwordVerify && (form.errors.passwordVerify as string)
                            }
                            type="password"
                            data-testid="registerFormPasswordVerifyInput"
                            {...field}
                          />
                        );
                      }}
                    </Field>
                  </div>
                  <div className="mb-sm">
                    <Field name="acceptedTerms">
                      {({ form, field }: FieldProps) => {
                        return (
                          <>
                            <CheckboxInput
                              className={styles.Checkbox}
                              data-testid="registerFormAcceptTerms"
                              {...field}>
                              {t('GENERIC.I_ACCEPT')}{' '}
                              <TextLink theme="basic" href={`${termsLink}`} openInNewTab>
                                {t('GENERIC.TERMS_AND_CONDITIONS')}
                              </TextLink>{' '}
                              {t('GENERIC.AND')}{' '}
                              <TextLink theme="basic" href={`${privacyLink}`} openInNewTab>
                                {t('GENERIC.PRIVACY_STATEMENT')}
                              </TextLink>
                            </CheckboxInput>
                            <div className={styles.Error}>
                              {form.touched.acceptedTerms && form.errors.acceptedTerms}
                            </div>
                          </>
                        );
                      }}
                    </Field>
                  </div>
                  {renderNewsletter && (
                    <div className="mb-sm">
                      <Field name="subscribe">
                        {({ form, field }: FieldProps) => {
                          return (
                            <>
                              <CheckboxInput
                                className={styles.Checkbox}
                                data-testid="registerFormSubscribe"
                                {...field}>
                                {t('DOMAIN.NEWSLETTER.DESCRIPTION')}
                              </CheckboxInput>
                              <div className={styles.Error}>
                                {form.touched.subscribe && form.errors.subscribe}
                              </div>
                            </>
                          );
                        }}
                      </Field>
                    </div>
                  )}
                  {!!error && (
                    <div data-testid="registerFormErrorContainer" className={styles.Error}>
                      {error}
                    </div>
                  )}
                  <div className={styles.FormFooter}>
                    <Button
                      isLoading={isLoading}
                      icon={faLongArrowRight}
                      data-testid="registerFormSubmitButton"
                      type="submit">
                      {t('DOMAIN.AUTHENTICATION.REGISTER')}
                    </Button>
                  </div>
                </>
              )}
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default RegisterForm;
