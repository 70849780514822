import React, { useState } from 'react';
import { useTranslation } from 'react-i18next/';

import { useSitelinks } from '@hooks/index';

import { RegisterForm } from '..';
import { RegisterFormValues } from '../RegisterForm/RegisterForm.props';
import { useStores } from '@models/RootStore/RootStore';

import Props from './RegisterFormWrapper.props';

const RegisterFormWrapper = ({ className }: Props) => {
  const { UserStore } = useStores();
  const { t } = useTranslation();
  const sitelinks = useSitelinks();

  const [registerIsLoading, setRegisterLoading] = useState(false);
  const [registerMessage, setRegisterMessage] = useState('');
  const [registerError, setRegisterError] = useState('');

  const registerValues = {
    email: '',
    password: '',
    passwordVerify: '',
    acceptedTerms: false,
    subscribe: false,
  };

  const handleRegisterSubmit = async ({ email, password, subscribe }: RegisterFormValues) => {
    try {
      setRegisterLoading(true);
      setRegisterError('');
      setRegisterMessage('');

      await UserStore.register(email, password, subscribe);
    } finally {
      setRegisterMessage(t('DOMAIN.AUTHENTICATION.REGISTRATION_SUCCESSFUL'));
      setRegisterLoading(false);
    }
  };

  return (
    <div className={className}>
      <RegisterForm
        onSubmit={handleRegisterSubmit}
        initialValues={registerValues}
        error={registerError}
        message={registerMessage}
        isLoading={registerIsLoading}
        termsLink={sitelinks?.terms_and_conditions[0]._slug || ''}
        privacyLink={sitelinks?.privacy_statement[0]._slug || ''}
      />
    </div>
  );
};

export default RegisterFormWrapper;
