import { useEffect, useState } from 'react';

import { __CLIENT_SIDE__ } from '@constants/env';

export const useDomain = () => {
  const [url, setUrl] = useState('');

  useEffect(() => {
    if (__CLIENT_SIDE__) {
      setUrl(window.location.origin);
    }
  }, [__CLIENT_SIDE__]);

  return url;
};
