import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next/';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons';

import { useDomain, useSitelinks } from '@hooks/index';
import { FB_LOGIN_URL } from '@constants/config';

import { Link } from '@components/containers';

import styles from './FacebookLoginButton.module.scss';
import Props from './FacebookLoginButton.props';

const FacebookLoginButton = ({ className }: Props) => {
  const { t } = useTranslation();
  const sitelinks = useSitelinks();
  const currentDomain = useDomain();

  const getLoginUrl = () => {
    return `${FB_LOGIN_URL}?access_token=${process.env.NEXT_PUBLIC_SOCIAL_LOGIN_TOKEN}&redirect_url=${currentDomain}/${sitelinks?.my_account[0]._slug}`;
  };

  return (
    <Link href={getLoginUrl()} className={cn(styles.FacebookLoginButton, className)}>
      <FontAwesomeIcon icon={faFacebookF} /> {t('DOMAIN.AUTHENTICATION.LOGIN_WITH_FACEBOOK')}
    </Link>
  );
};

export default FacebookLoginButton;
