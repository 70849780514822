import React, { useState } from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next/';

import { LoginForm } from '..';
import { useSitelinks } from '@hooks/useSiteLinks';

import { LoginFormValues } from '../LoginForm/LoginForm.props';
import Props from './LoginFormWrapper.props';
import { useStores } from '@models/RootStore/RootStore';

const LoginFormWrapper = ({ shouldRedirect = true, onLogin, className }: Props) => {
  const [loginIsLoading, setLoginLoading] = useState(false);
  const [loginError, setLoginError] = useState('');

  const router = useRouter();
  const { UserStore } = useStores();
  const { t } = useTranslation();
  const siteLinks = useSitelinks();

  const loginValues = {
    email: '',
    password: '',
    rememberMe: false,
  };

  const handleLoginSubmit = async ({ email, password, rememberMe }: LoginFormValues) => {
    try {
      setLoginLoading(true);
      setLoginError('');

      await UserStore.login(email, password, rememberMe);

      if (onLogin) onLogin();
      if (shouldRedirect) router.push('/');
    } catch (e: any) {
      if (e.response?.status === 400) {
        setLoginError(t('VALIDATION.INCORRECT_CREDENTIALS'));
      } else {
        setLoginError(t('DOMAIN.AUTHENTICATION.LOGIN_FAILED'));
      }
    } finally {
      setLoginLoading(false);
    }
  };

  const forgotPasswordUrl =
    siteLinks && siteLinks.forgot_password_page[0]
      ? `/${siteLinks.forgot_password_page[0]._slug}`
      : '/';

  return (
    <div className={className}>
      <LoginForm
        onSubmit={handleLoginSubmit}
        initialValues={loginValues}
        error={loginError}
        isLoading={loginIsLoading}
        forgotPasswordUrl={forgotPasswordUrl}
      />
    </div>
  );
};

export default LoginFormWrapper;
