import React from 'react';
import * as Yup from 'yup';
import { Formik, Form, Field, FieldProps } from 'formik';
import { useTranslation } from 'react-i18next/';
import { faLongArrowRight } from '@fortawesome/pro-regular-svg-icons';

import Button from '@components/ui/Button/Button';
import CheckboxInput from '@components/ui/CheckboxInput/CheckboxInput';
import Input from '@components/ui/Input/Input';
import TextLink from '@components/ui/TextLink/TextLink';

import styles from './LoginForm.module.scss';
import Props from './LoginForm.props';

const LoginForm = ({ initialValues, onSubmit, forgotPasswordUrl, error, isLoading }: Props) => {
  const { t } = useTranslation();

  const validationScheme = Yup.object().shape({
    email: Yup.string()
      .email(t('VALIDATION.E_MAIL_INVALID'))
      .required(t('VALIDATION.FIELD_REQUIRED')),
    password: Yup.string().required(t('VALIDATION.FIELD_REQUIRED')),
  });

  return (
    <div className={styles.LoginForm}>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationScheme}
        validateOnBlur={false}
        validateOnChange>
        {() => {
          return (
            <Form>
              <div className="mb-sm">
                <Field name="email">
                  {({ form, field }: FieldProps) => {
                    return (
                      <Input
                        label={t('DOMAIN.AUTHENTICATION.EMAIL')}
                        error={form.touched.email && (form.errors.email as string)}
                        data-testid="loginFormEmailInput"
                        type="email"
                        {...field}
                      />
                    );
                  }}
                </Field>
              </div>
              <div className="mb-sm">
                <Field name="password">
                  {({ form, field }: FieldProps) => {
                    return (
                      <Input
                        label={t('DOMAIN.AUTHENTICATION.PASSWORD')}
                        error={form.touched.password && (form.errors.password as string)}
                        type="password"
                        data-testid="loginFormPasswordInput"
                        {...field}
                      />
                    );
                  }}
                </Field>
              </div>
              <div className="mb-sm">
                <Field name="rememberMe">
                  {({ field }: FieldProps) => {
                    return (
                      <CheckboxInput {...field}>
                        {t('DOMAIN.AUTHENTICATION.REMEMBER_ME')}
                      </CheckboxInput>
                    );
                  }}
                </Field>
              </div>
              {!!error && (
                <div data-testid="loginFormErrorContainer" className={styles.Error}>
                  {error}
                </div>
              )}
              <div className={styles.FormFooter}>
                <Button
                  isLoading={isLoading}
                  icon={faLongArrowRight}
                  data-testid="loginFormSubmitButton"
                  type="submit"
                  className="mb-sm">
                  {t('DOMAIN.AUTHENTICATION.LOGIN')}
                </Button>
                <TextLink href={forgotPasswordUrl} noIndex>
                  {t('DOMAIN.AUTHENTICATION.FORGOT_PASSWORD')}
                </TextLink>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default LoginForm;
